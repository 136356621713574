


























import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {Route} from '@/models/category/Route';
import {SearchResult} from "@/models/SearchResult";
import {RouteService, RouteResult} from '@/store/category/RouteService';
import {SuggestionModel, SuggestionType} from "@/components/suggestion/base-suggestion/SuggestionModel";
import Suggestion from "@/components/suggestion/base-suggestion/Suggestion.vue";
import {CustomerType} from "@/constants/Customer";
import {CustomerSuggestionModel} from "@/components/suggestion/customer-suggestion/CustomerSuggestionModel";

@Component({
    components: {
        Suggestion
    }
})

export default class RouteSelect extends Vue {
    @Prop({default: () => null}) value!: SuggestionModel;
    @Ref("suggestion") suggestion!: any;
    @Prop({default: 'Nhập nội dung cần tìm'}) noFoundText!: string;
    @Prop({default: 'Thêm tuyến đường'}) addLabel!: string;
    @Prop({default: 'Không tìm thấy tuyến đường'}) notFoundText!: string;
    @Prop({default: SuggestionType.input}) type!: SuggestionType;
    @Prop({default: ""}) placeholder!: string; // popup | input
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: false}) showAddButton!: boolean;
    @Prop({default: false}) allowAddWhenNotExists!: boolean;
    @Prop({default: false}) isAddFull!: boolean;
    @Prop({default: true}) isShowSelected!: boolean;
    @Prop({default: 20}) pageSize!: number;
    @Prop({default: CustomerType.personal}) customerType!: CustomerType;
    @Prop({default: true}) allowAdd!: boolean;
    @Prop({default: () => []}) listSelected!: any;
    data: Array<RouteResult> = new Array<RouteResult>();
    isLoading = false;
    totalPage = 0;
    listSuggestion: any = [];
    listRoute: any[] = [];

    onRouteSelected(value: any) {
        const route = this.data.find((item: any) => {
            return item.id === value;
        });
        this.$emit('input', value);
        this.$emit('change', route);
    }

    onAdd(routeName: string) {
        if (!routeName) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Vui lòng nhập tên tuyến đường",
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
            return;
        }

        const routeService = new RouteService();
        routeService.insert(new Route(routeName))
            .then((result: any) => {
                if (result.code > 0) {
                    this.onSearch();
                }
            });
    }

    onShow() {
        this.onSearch();
    }

    onSearch(search: string = '') {
        this.isLoading = true;
        const routeService = new RouteService();
        routeService.autoSugesst(search)
            .then((result: SearchResult<RouteResult>) => {
                this.isLoading = false;
                if (result.code > 0) {
                    this.listSuggestion = result.items.map((route: any) => {
                        const suggestion = new SuggestionModel();
                        suggestion.id = route.id;
                        suggestion.text = route.name;
                        suggestion.isSelected = this.checkIsSelected(route.id);
                            suggestion.data = route;
                        return suggestion;
                    });
                }
            })
            .catch(e => this.isLoading = false);
    }

    private checkIsSelected(id: string): boolean {
        const index = this.listSelected.findIndex(
            (item: SuggestionModel) => {
                return item.id === id;
            }
        );
        return index !== -1;
    }
}
