




























































import {Component, Emit, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {CustomerAddress} from '@/models/customer/CustomerAddress';
import CustomerAddressService from '@/views/customer/address/CustomerAddressService';
import {AttributeResult} from '@/models/attribute/AttributeResult';
import CustomAttribute from '@/components/custom-attribute/CustomAttribute.vue';
import {CustomAttributeResult} from '@/models/attribute/CustomAttributeResult';
import {required} from "vee-validate/dist/rules";
import i18n from "@/i18n";
import ProvinceDistrictSelect from "@/components/ProvinceDistrictSelect.vue";
import {ProvinceDistrictResult} from "@/models/ProvinceDistrictResult";
import RouteSelect from "@/components/category/RouteSelect.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {CustomerAddressRoute} from "@/models/attribute/CustomerAddressRoute";
import {SuggestionModel} from "@/components/suggestion/base-suggestion/SuggestionModel";
import cloneDeep from 'lodash/cloneDeep';

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

@Component({
    components: {
        CustomAttribute,
        ValidationProvider,
        ValidationObserver,
        ProvinceDistrictSelect,
        RouteSelect,
        VuePerfectScrollbar
    }
})
export default class CustomerAddressForm extends Vue {
    @Ref('provinceElement') provinceElement!: any;
    @Prop() customerId!: string;

    isShow = false;
    readonly = false;
    isSaving = false;
    customerAddress = new CustomerAddress();
    customerAddressService = new CustomerAddressService();
    settings = {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
    };

    constructor() {
        super();
    }

    @Watch('isShow')
    onShown(value: boolean) {
        if (value) {
            // setTimeout(() => {
            //     this.provinceElement.$el.querySelector('input').focus();
            // }, 100);
        } else {
            this.resetModel();
        }

    }

    @Emit('added')
    onAdded(customerAddress: CustomerAddress | undefined) {
        return customerAddress;
    }

    @Emit('updated')
    onUpdated(customerAddress: CustomerAddress | undefined) {
        return customerAddress;
    }

    get popupTitle() {
        return this.customerAddress.id ? this.$t('Update address') : this.$t('Insert address');
    }

    get listRouteSelected() {
        if (this.customerAddress.routes === null || this.customerAddress.routes === undefined
            || this.customerAddress.routes.length === 0) {
            return [];
        }
        return this.customerAddress.routes.map((route: CustomerAddressRoute) => {
            const suggestion = new SuggestionModel();
            suggestion.id = route.id;
            suggestion.text = route.name;
            suggestion.data = route;
            return suggestion;
        })
    }

    async add() {
        this.isShow = true;
        this.readonly = false;
        this.customerAddress = new CustomerAddress();
        await this.getDefaultAttribute();
    }

    async edit(customerAddress: CustomerAddress) {
        this.isShow = true;
        this.readonly = false;
        this.customerAddress = cloneDeep(customerAddress);
        await this.getDefaultAttribute(customerAddress.customAttributes);
    }

    async showDetail(customerAddress: CustomerAddress) {
        this.customerAddress = cloneDeep(customerAddress);
        this.isShow = true;
        this.readonly = true;
        await this.getDefaultAttribute(customerAddress.customAttributes);
    }

    onDistrictChange(provinceDistrict: ProvinceDistrictResult) {
        if (this.customerAddress) {
            this.customerAddress.provinceId = provinceDistrict ? provinceDistrict.provinceId : '';
            this.customerAddress.provinceName = provinceDistrict ? provinceDistrict.provinceName : '';
            this.customerAddress.districtName = provinceDistrict ? provinceDistrict.districtName : '';
        }
    }

    onRouteChange(routes: SuggestionModel[]) {
        console.log(routes);
        // this.customerAddress.routeId = route.id;
        // this.customerAddress.routeName = route.name;
    }

    onRouteSelect(routes: SuggestionModel[]) {
        this.customerAddress.routes = routes.map((suggestion: SuggestionModel) => {
            return {
                id: suggestion.id,
                name: suggestion.text
            } as CustomerAddressRoute;
        });
    }

    onSelectedRouteRemoved(suggestion: SuggestionModel) {
        const removedRouteIndex = this.customerAddress.routes.findIndex((route: CustomerAddressRoute) => {
            return route.id === suggestion.id;
        });
        if (removedRouteIndex != -1) {
            console.log(removedRouteIndex);
            this.$delete(this.customerAddress.routes, removedRouteIndex);
        }
    }

    async save() {
        // Update existing customer address.
        this.isSaving = true;
        if (this.customerAddress.id) {
            try {
                const result = await this.customerAddressService.update(this.customerId, this.customerAddress.id, this.customerAddress);
                if (result.code > 0) {
                    this.$vs.notify({
                        title: '',
                        text: result.message,
                        color: 'success'
                    });
                    this.onUpdated(result.data);
                    this.customerAddress = new CustomerAddress();
                    this.isShow = false;
                    this.isSaving = false;
                }
            } catch (error) {
                this.isSaving = false;
                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: 'danger'
                });
            }
        } else {
            // Add new customer address.
            try {
                const result = await this.customerAddressService.insert(this.customerId, this.customerAddress);
                if (result && result.code > 0) {
                    this.$vs.notify({
                        title: '',
                        text: result.message,
                        color: 'success'
                    });
                    this.onAdded(result.data);
                    this.customerAddress = new CustomerAddress();
                    this.isShow = false;
                    this.isSaving = false;
                }
            } catch (error) {
                this.isSaving = false;
                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: 'danger'
                });
            }
        }
    }

    @Emit('closed')
    onClosed() {
    }

    private async getDefaultAttribute(customAttributes?: CustomAttributeResult[]) {
        const result = await this.customerAddressService.getDefaultAttribute();
        if (result.code > 0) {
            this.customerAddress.customAttributes = result.items.map((attributeResult: AttributeResult) => {
                const currentAttribute = customAttributes && customAttributes.length > 0
                    ? customAttributes.find((customAttribute: CustomAttributeResult) => {
                        return customAttribute.attributeId === attributeResult.id;
                    }) : null;
                return {
                    attributeId: attributeResult.id,
                    attributeName: attributeResult.name,
                    attributeDescription: attributeResult.description,
                    type: attributeResult.type,
                    attributeValueId: currentAttribute ? currentAttribute.attributeValueId : '',
                    attributeValueName: currentAttribute ? currentAttribute.attributeValueName : '',
                    isMultiple: attributeResult.isMultiple,
                    attributeValues: attributeResult.values
                } as CustomAttributeResult;
            });
        }
    }

    private resetModel() {
        this.customerAddress.id = '';
        this.customerAddress.provinceId = '';
        this.customerAddress.provinceName = '';
        this.customerAddress.districtId = '';
        this.customerAddress.districtName = '';
        this.customerAddress.routes = [];
    }
}
