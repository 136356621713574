import axios from 'axios';
import {Route} from '@/models/category/Route';
import {SearchResult} from "@/models/SearchResult";
import {RelateRoute} from "@/models/category/RelateRoute";

export class RouteResult {
    id: string;
    name: string;

    constructor(id: string, name: string)
    {
        this.id = id;
        this.name = name;
    }
}

export class RouteService{
    url = `${process.env.VUE_APP_CORE_URL}api/v1/route`;
    search(keyword: string, page: number = 1, pageSize: number = 10):
        Promise<SearchResult<Route>>{
            return axios.get(this.url, {
                params: {
                    keyword: keyword,
                    page:page,
                    pageSize:pageSize
                }
          });
    }

    autoSugesst(keyword: string):
        Promise<SearchResult<RouteResult>>{
        return axios.get(`${this.url}/auto-sugesst`, {
            params: {
                id : "",
                keyword : keyword,
                pageSize : 100000
            }
        });
    }

    getRelate(id: string | undefined, keyword: string, pageSize: number):
        Promise<SearchResult<RelateRoute>>{
            return axios.get(`${this.url}/auto-sugesst`, {
                params: {
                    id : id,
                    keyword : keyword,
                    pageSize : pageSize
                }
            });
    }

    insert(route: Route):
        Promise<any>{
        return axios.post(this.url, route);
    }

    update(route: Route):
        Promise<any>{
        return axios.post(`${this.url}/update`, route);
    }

    delete(id: string):
        Promise<any>{
        return axios.delete(`${this.url}/${id}`);
    }

    changeActive(id: string, isActive: boolean):
        Promise<any>{
        return axios.post(`${this.url}/change-active`, {
            id: id,
            isActive:  isActive
        });
    }
}