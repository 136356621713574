import {RelateRoute} from '@/models/category/RelateRoute';

export class Route {
    id: string | undefined;
    name: string;
    description: string;
    relateRoute: Array<RelateRoute>;

    constructor(name: string = '') {
        this.name = name;
        this.description = '';
        this.relateRoute = new Array<RelateRoute>();
    }
}

